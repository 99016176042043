import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

// Components
import Layout from '../../components/Layout';
import AboutBanner from '../../components/AboutBanner';
import UnderConstruction from '../../components/UnderConstruction';

// Images
import Hero from '../../img/about_07_m_w.jpg';
import MobHero from '../../img/about_07_m_h.jpg';

// Language
import { getPackOf } from '../../lang';
const LANG = getPackOf('zh');

const Design = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { glob: "**/src/markdown/about/design.md" }) {
        frontmatter {
          disable
          sectionOne {
            title
            subTitle
            img {
              publicURL
            }
            description
          }
          sectionTwo {
            title
            subTitle
            img {
              publicURL
            }
            description
          }
          sectionThree {
            description
            subTitle
            title
            img {
              publicURL
            }
            bgImg {
              publicURL
            }
          }
          sectionFour {
            title
            subTitle
            img {
              publicURL
            }
            description
          }
          sectionFive {
            title
            subTitle
            img {
              publicURL
            }
            description
          }
        }
      }
    }
  `);

  const {
    markdownRemark: {
      frontmatter: { disable, sectionOne, sectionTwo, sectionThree, sectionFour, sectionFive },
    },
  } = data;

  return (
    <Layout>
      <AboutBanner
        pageTitle={LANG.板金工藝設計}
        subTitle={LANG.板金工藝設計副標題}
        bgImg={Hero}
        mobileBgImg={MobHero}
      />
      {disable ? (
        <UnderConstruction />
      ) : (
        <>
          <section id="pro-1" className="py-5 bg-img">
            <div className="container">
              <div className="d-flex flex-wrap">
                <div className="col-12 col-md-5">
                  <img src={sectionOne.img.publicURL} alt="design" className="img-thumbnail" />{' '}
                </div>
                <div className="col-12 col-md-7 align-self-center ">
                  <p className="h3 mb-4">{sectionOne.title}</p>
                  <p>
                    <strong>{sectionOne.subTitle}</strong>
                  </p>
                  <p style={{ whiteSpace: 'pre-line' }}>{sectionOne.description}</p>
                  <p className="contact-ds">
                    <Link to="/#Location">{LANG.合作洽詢}</Link>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="pro-2" className="py-5 bg-img bg-light">
            <div className="container">
              <div className="d-flex flex-wrap">
                <div className="col-12 col-md-7 align-self-center">
                  <p className="h3 mb-4">{sectionTwo.title}</p>
                  <p>
                    <strong>{sectionTwo.subTitle}</strong>
                  </p>
                  <p style={{ whiteSpace: 'pre-line' }}>{sectionTwo.description}</p>
                  <p className="contact-ds">
                    <Link to="/#Location">{LANG.合作洽詢}</Link>
                  </p>
                </div>
                <div className="col-12 col-md-5">
                  <img src={sectionTwo.img.publicURL} alt="design" className="img-thumbnail" />
                </div>
              </div>
            </div>
          </section>
          <section
            id="pro-3"
            className="py-5 bg-img text-white"
            style={{ backgroundImage: `url(${sectionThree.bgImg.publicURL})` }}
          >
            <div className="container">
              <div className="d-flex flex-wrap">
                <div className="col-12 col-md-5">
                  <img src={sectionThree.img.publicURL} alt="design" className="img-thumbnail" />{' '}
                </div>
                <div className="col-12 col-md-7 align-self-center">
                  <p className="h3 mb-4">{sectionThree.title}</p>
                  <p>
                    <strong>{sectionThree.subTitle}</strong>
                  </p>
                  <p style={{ whiteSpace: 'pre-line' }}>{sectionThree.description}</p>
                  <p className="contact-ds">
                    <Link to="/#Location">{LANG.合作洽詢}</Link>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section id="pro-4" className="py-5 bg-img bg-light">
            <div className="container">
              <div className="d-flex flex-wrap">
                <div className="col-12 col-md-7 align-self-center ">
                  <p className="h3 mb-4">{sectionFour.title}</p>
                  <p>
                    <strong>{sectionFour.subTitle}</strong>
                  </p>
                  <p style={{ whiteSpace: 'pre-line' }}>{sectionFour.description}</p>
                  <p className="contact-ds">
                    <Link to="/#Location">{LANG.合作洽詢}</Link>
                  </p>
                </div>
                <div className="col-12 col-md-5">
                  <img src={sectionFour.img.publicURL} alt="design" className="img-thumbnail" />
                </div>
              </div>
            </div>
          </section>
          <section id="pro-5" className="py-5 bg-img">
            <div className="container">
              <div className="d-flex flex-wrap">
                <div className="col-12 col-md-5">
                  <img src={sectionFive.img.publicURL} alt="design" className="img-thumbnail" />
                </div>
                <div className="col-12 col-md-7 align-self-center ">
                  <p className="h3 mb-4">{sectionFive.title}</p>
                  <p>
                    <strong>{sectionFive.subTitle}</strong>
                  </p>
                  <p style={{ whiteSpace: 'pre-line' }}>{sectionFive.description}</p>
                  <p className="contact-ds">
                    <Link to="/#Location">{LANG.合作洽詢}</Link>
                  </p>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </Layout>
  );
};

export default Design;
